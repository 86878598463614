import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { withApollo } from 'next-with-apollo';
import { NextPageContext } from 'next';

export const apolloClient = ({
  initialState,
  ctx,
}: {
  initialState?: NormalizedCacheObject;
  ctx?: NextPageContext;
} = {}): ApolloClient<unknown> =>
  new ApolloClient({
    uri: URLS.graphql,
    cache: new InMemoryCache({ addTypename: false }).restore(
      initialState || {},
    ),
    ssrMode: !process.browser,
    credentials: 'include',
    headers: {
      Cookie: (!process.browser && ctx?.req?.headers?.cookie) || '',
    },
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });
export default withApollo(
  ({ initialState, ctx }) => {
    return apolloClient({ initialState, ctx });
  },
  {
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    },
  },
);
