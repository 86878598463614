import App, { AppProps, AppContext } from 'next/app';
import * as Sentry from '@sentry/node';
import Head from 'next/head';
import 'aos/dist/aos.css';
import 'react-medium-image-zoom/dist/styles.css';
import React from 'react';
import { getDataFromTree } from '@apollo/client/react/ssr';

import { appWithTranslation } from 'lib/i18n';
import withApollo from 'lib/apollo/withApollo';

import GlobalStyle from '../src/themes/globalStyle';

import 'rc-slider/assets/index.css';
import 'nprogress/nprogress.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import 'slick-carousel/slick/slick.css';

Sentry.init({
  enabled: SENTRY_ENABLED && process.env.NODE_ENV === 'production',
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
});
Sentry.configureScope(scope => {
  scope.setTag('project', 'app');
});

const MyApp = ({
  Component,
  pageProps,
  err,
}: AppProps & { err: unknown }): JSX.Element => {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="robots" content={ROBOTS} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/meta/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/meta/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/meta/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/meta/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/meta/safari-pinned-tab.svg"
          color="#c80a32"
        />
        <link rel="shortcut icon" href="/static/meta/favicon.ico" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content="/static/meta/browserconfig.xml"
        />
        <meta name="theme-color" content="#c80a32" />
      </Head>
      <Component {...pageProps} err={err} />
      <GlobalStyle />
    </>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => ({
  ...(await App.getInitialProps(appContext)),
});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(withApollo(MyApp, { getDataFromTree }));
