import { createGlobalStyle } from 'styled-components';

import theme from 'themes/default';

const { primary } = theme.global;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Brix Sans';
    src: local('Brix Sans Light'), local('BrixSansLight'),
      url('/static/font/subset-BrixSansLight.woff2') format('woff2'),
      url('/static/font/subset-BrixSansLight.woff') format('woff'),
      url('/static/font/subset-BrixSansLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brix Sans';
    src: local('Brix Sans Bold'), local('BrixSansBold'),
      url('/static/font/subset-BrixSansBold.woff2') format('woff2'),
      url('/static/font/subset-BrixSansBold.woff') format('woff'),
      url('/static/font/subset-BrixSansBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brix Sans';
    src: local('Brix Sans Regular'), local('BrixSansRegular'),
      url('/static/font/subset-BrixSansRegular.woff2') format('woff2'),
      url('/static/font/subset-BrixSansRegular.woff') format('woff'),
      url('/static/font/subset-BrixSansRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brix Sans';
    src: local('Brix Sans Italic'), local('BrixSansRegular-Italic'),
      url('/static/font/subset-BrixSansRegular-Italic.woff2') format('woff2'),
      url('/static/font/subset-BrixSansRegular-Italic.woff') format('woff'),
      url('/static/font/subset-BrixSansRegular-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Brix Sans';
    src: local('Brix Sans Medium'), local('BrixSansMedium'),
      url('/static/font/subset-BrixSansMedium.woff2') format('woff2'),
      url('/static/font/subset-BrixSansMedium.woff') format('woff'),
      url('/static/font/subset-BrixSansMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  * {
    font-family: 'Brix Sans', sans-serif;
    box-sizing: border-box;
  }

  html,
  body {
    max-width: 100%;
  }

  html {
    min-height: 100%;
    position: relative;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  body {
    font-size: ${theme.text.baseSize}px;
    margin: 0 !important;
    padding: 0 !important;
    background: ${theme.backgrounds.primary};
    color: ${theme.text.primary};
  }

  a {
    text-decoration: none;
    color: ${primary};
  }

  #sizeid-user {
    display: none;
  }

  #nprogress {
    .bar {
      background: ${primary};
    }

    .peg {
      box-shadow: 0 0 10px ${primary}, 0 0 5px ${primary};
    }

    .spinner-icon {
      border-top-color: ${primary};
      border-left-color: ${primary};
    }
  }
`;

export default GlobalStyle;
